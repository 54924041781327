body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image:url('./assets/images/background_01.webp');
  background-size:     auto 110vh;        
  background-position: center top;
  background-attachment: fixed;  
  overflow-x: hidden; 
}

