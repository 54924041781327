.CookiesDiv {
 

  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: -1;
  width: 100%;
  height: 300px;
}

.Cookies {
    height: 4vw;
   
}




.Cookie1 {
  
  animation: slideInFromLeft 1s ease-out 0s 1 ;
  right: 30vw;
  bottom: 20%;
  position: absolute;
}

.Cookie2 {
  
  animation: slideInFromLeft 3s ease-out 0s 1 ;
  right: 20vw;
  bottom: 1%;
  position: absolute;
}

.Cookie3 {
  
  animation: slideInFromLeft 2s ease-out 0s 1 ;
  right: 5vw;
  bottom: 0;
  position: absolute;
}


  @keyframes slideInFromLeft {
    0% {

      transform: translateX(-1000px);
    }

    100% {

      transform: translateX(0);
    }
  }
