.Input {
    width: 90%;
    background-color: #000000;
    border: solid;
    color: #ffcc00;
    outline: none;
    box-sizing: border-box;
    font: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
    
    
    border-radius: 0.3rem;
    
    
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 30px #ffbb00, 
  0 0 3px #ffbb00, 0 0 3px #ff0000, 0 0 10px #ff0000, 0 0 3px #ff0000;
}

.Input:disabled {
    color: #333;
    cursor: not-allowed;
}



Input:-webkit-autofill,
Input:-webkit-autofill:hover,
Input:-webkit-autofill:focus,
Input:-webkit-autofill:active {
    transition: all 9999s ease-in-out 0s;
   
    
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #941775;
}

.Input:hover {
    color: #ffe476;
    
}






.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;

}





.Invalid {
    
    background-color: #550000;

}