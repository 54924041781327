@import url(https://fonts.googleapis.com/css?family=VT323&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css?family=VT323&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image:url(/static/media/background_01.3d1564ca.webp);
  background-size:     auto 110vh;        
  background-position: center top;
  background-attachment: fixed;  
  overflow-x: hidden; 
}



.App_App__16ZpL {
  text-align: center;

}

.App_Scanline__37CWa {
  width: 100%;
  height: 200px;
  z-index: 8;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.2) 10%,
      rgba(0, 0, 0, 0.1) 100%
  );
  opacity: 0.1;
  position: absolute;
  bottom: 100%;
  -webkit-animation: App_scanline__2GF0E 10s linear infinite;
          animation: App_scanline__2GF0E 10s linear infinite;
}



@-webkit-keyframes App_scanline__2GF0E {
  0% {
      bottom: 100%;
  }
  80% {
      bottom: 100%;
  }
  100% {
      bottom: 0%;
  }
}



@keyframes App_scanline__2GF0E {
  0% {
      bottom: 100%;
  }
  80% {
      bottom: 100%;
  }
  100% {
      bottom: 0%;
  }
}



.Box_Box__TYy1V {
    margin: 2rem;
    padding:2rem;
    

    background-color: #55555577;
    border-style: solid;
    border-radius: 0.3rem;
    font-family: "VT323", monospace;
    
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 1100)));
    color: rgb(50, 0, 0);
    text-shadow: 0 0 1px #fff, 0 0 3px #fff, 0 0 10px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00;
    -webkit-animation: Box_glitch1__3eFXp 20s infinite;
            animation: Box_glitch1__3eFXp 20s infinite;
    
}

a {
    color: rgb(50, 0, 0);
    text-decoration: none;
}

a:hover {
    color:#ffe476;
}

h3, h4 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    text-transform: uppercase;
}

h5 {
    margin-top: 0.2em;
    margin-bottom: 0.4em;
    text-transform: uppercase;
}

ul {
    text-align: left;
}

li {
    margin-bottom: 1rem;
}

p{
    text-align: justify;
}

@media (min-width: 1100px) {
    .Box_Box__TYy1V{
        padding-left: 10rem;
        padding-right: 10rem;
        font-size: 2.5rem;
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 30px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
        -webkit-animation: Box_textShadow__DctsO 5s linear infinite;
                animation: Box_textShadow__DctsO 5s linear infinite;
    }
}


@-webkit-keyframes Box_textShadow__DctsO {
    0% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00;
    }
    1% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 15px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
    80% {
        text-shadow: 0 0 4px #fff, 0 0 7px #fff, 0 0 10px #ff0000, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
}


@keyframes Box_textShadow__DctsO {
    0% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00;
    }
    1% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 15px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
    80% {
        text-shadow: 0 0 4px #fff, 0 0 7px #fff, 0 0 10px #ff0000, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
}

@-webkit-keyframes Box_glitch1__3eFXp {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, -0.3deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-0.1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}

@keyframes Box_glitch1__3eFXp {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, -0.3deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-0.1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}
.Button_Button__3gFiX {
    width: 90%;
    
    background-color: #000000;
    border: solid;
    color: #ffcc00;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
    
    
    
    border-radius: 0.3rem;
    font-weight: bold;
    font-size: calc(20px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    text-transform: uppercase;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 30px #ffbb00, 
  0 0 3px #ffbb00, 0 0 3px #ff0000, 0 0 10px #ff0000, 0 0 3px #ff0000;
}

.Button_Button__3gFiX:disabled {
    color: #555;
    cursor: not-allowed;
}



.Button_Success__2Rka1 {
    color: #5C9210;
}

.Button_Danger__2ogZq {
    color: #941775;
}

.Button_Button__3gFiX:hover {
    color: #ffe476;
    
}



@media (min-width: 1100px) {
    .Button_Button__3gFiX{
        min-width: 190px;
        width: 30%;
    }
}
.Cup_CupDiv__lwSU4 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: Cup_slideInFromRight__2MQqM 1s ease-out 0s 1;
          animation: Cup_slideInFromRight__2MQqM 1s ease-out 0s 1;
}


.Cup_Cup__2Dez3 {
    height: 150px;
    
    flex: auto;
    z-index: 10;
}

.Cup_Level__1WDaZ {
  height: 15px;
  position: relative;
  bottom: 100px;
  z-index: 9;
  -webkit-animation: Cup_fillCup__2WQb5 3s ease-out 0s 1;
          animation: Cup_fillCup__2WQb5 3s ease-out 0s 1;
}


@media (min-width: 768px) {
    .Cup_Cup__2Dez3{
        height: 300px;
        
    }
    .Cup_Level__1WDaZ{
      bottom: 200px;
      height: 32px;
      -webkit-animation: Cup_fillCupBig__1878d 3s ease-out 0s 1;
              animation: Cup_fillCupBig__1878d 3s ease-out 0s 1;
    }
}


  .Cup_steam__34UfR{
  position: relative;
  top:0px;
  left:0px;
  

  /* -webkit-animation: steam 5s linear infinite 3s;
  -moz-animation: steam 5s linear infinite 3s;
  -ms-animation: steam 5s linear infinite 3s;
  -o-animation: steam 5s linear infinite 3s; */
  -webkit-animation: Cup_steam__34UfR 5s linear infinite 3s;
          animation: Cup_steam__34UfR 5s linear infinite 3s;
    
  }
  .Cup_steam__34UfR:before,
  .Cup_steam__34UfR:after{
    
    
    content: " ";
    position: absolute;
    top:20px;
    left:10px;
    border-right: 0px solid transparent;
    border-top: 2px solid #ffffff;
    border-left: 5px solid #ffffff;
    border-bottom: 2px solid transparent;
    width: 40px;
    height: 70px;
    z-index: 2;
    border-radius: 80% 0 10% 10%;
   transform: rotate(168deg) skewY(10deg);
  
   -webkit-filter: blur(7px);
   -moz-filter: blur(7px);
   -o-filter: blur(7px);
   -ms-filter: blur(7px);
    filter: blur(7px);
   }
  
  .Cup_steam__34UfR:after{
  top:0px;
  left:-50px;
  z-index: 50;
  border-radius: 80% 0 10% 10%;
  transform: rotate(315deg) skewY(10deg);
  
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
  }
  

  @media (max-width: 768px){
    .Cup_steam__34UfR:before,
    .Cup_steam__34UfR:after{
        width: 20px;
        height: 50px;
    }
  }
  
  
  @-webkit-keyframes Cup_steam__34UfR {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @keyframes Cup_steam__34UfR {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }



  @-webkit-keyframes Cup_slideInFromRight__2MQqM {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }



  @keyframes Cup_slideInFromRight__2MQqM {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }


  @-webkit-keyframes Cup_fillCup__2WQb5 {
    0% { bottom: 50px; opacity: .01; visibility: hidden; }
    20% { bottom: 70px; opacity: 0.2; }
    40% { bottom: 90px; opacity: 0.4; }
    60% { bottom: 110px; opacity: 0.5; }
    80% { bottom: 100px; opacity: .075; }
    100% { bottom: 110px; opacity: 0.80; visibility: visible; }
  }


  @keyframes Cup_fillCup__2WQb5 {
    0% { bottom: 50px; opacity: .01; visibility: hidden; }
    20% { bottom: 70px; opacity: 0.2; }
    40% { bottom: 90px; opacity: 0.4; }
    60% { bottom: 110px; opacity: 0.5; }
    80% { bottom: 100px; opacity: .075; }
    100% { bottom: 110px; opacity: 0.80; visibility: visible; }
  }

  @-webkit-keyframes Cup_fillCupBig__1878d {
    0% { bottom: 100px; opacity: .01; visibility: hidden; }
    20% { bottom: 120px; opacity: 0.2; }
    40% { bottom: 150px; opacity: 0.4; }
    60% { bottom: 180px; opacity: 0.5; }
    80% { bottom: 150px; opacity: .075; }
    100% { bottom: 200px; opacity: 0.80; visibility: visible; }
  }

  @keyframes Cup_fillCupBig__1878d {
    0% { bottom: 100px; opacity: .01; visibility: hidden; }
    20% { bottom: 120px; opacity: 0.2; }
    40% { bottom: 150px; opacity: 0.4; }
    60% { bottom: 180px; opacity: 0.5; }
    80% { bottom: 150px; opacity: .075; }
    100% { bottom: 200px; opacity: 0.80; visibility: visible; }
  }
.NavButton_Button__3mz08 {
    width: 90%;
    
    background-color: #000000;
    border: solid;
    color: #ffcc00;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
    
    
    
    border-radius: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 30px #ffbb00, 
  0 0 3px #ffbb00, 0 0 3px #ff0000, 0 0 10px #ff0000, 0 0 3px #ff0000;
}

.NavButton_Button__3mz08:disabled {
    color: #555;
    cursor: not-allowed;
}



.NavButton_Success__3Qg7V {
    color: #5C9210;
}

.NavButton_Danger__25mtG {
    color: #941775;
}

.NavButton_Button__3mz08:hover {
    color: #ffe476;
    
}



@media (min-width: 1100px) {
    .NavButton_Button__3mz08{
        min-width: 190px;
        
    }
}
.Nav_Nav__cViw5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;

    font-family: "VT323", monospace;
    font-size: 2rem;
    margin: 0;
    padding: 1em 1em 0em 1em;
    

    
}

.Nav_LogoOrder__hrz16 {
    order:3;
}


@media (max-width: 790px){
    .Nav_LogoOrder__hrz16 {
        order: 1;
        flex-basis: 100%;
        height: 0;
    }
    .Nav_Nav__cViw5{
        
    }
    .Nav_Flex__3m8rt{
        flex-basis: 100%;
    }

}


.Logo_LogoDiv__1i7uR{
    margin-left: 50px;
    margin-right: 50px;
    
}

.Logo_Logo__1N0xH{
    height: 80px;
    width: 80px;
    -webkit-filter: drop-shadow(0rem 0rem 0.5rem #ffbb00);
            filter: drop-shadow(0rem 0rem 0.5rem #ffbb00);
    -webkit-animation: Logo_glitch1__3ZpXp 10s infinite;
            animation: Logo_glitch1__3ZpXp 10s infinite;
    
}

.Logo_Logo__1N0xH:hover{
    -webkit-animation: Logo_glitch1__3ZpXp 0.5s infinite;
            animation: Logo_glitch1__3ZpXp 0.5s infinite;
}

@-webkit-keyframes Logo_glitch1__3ZpXp {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, 40deg);
        opacity: 0.1;
    }
    7.2% {
        transform: skew(70deg, -40deg);
        opacity: 1;
    }
    9% {
        transform: skew(0deg, 0deg);
        opacity: 0.2;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-45deg, 0.2deg);
        opacity: 0.75;
    }
    56% {
        transform: skew(0deg, -3deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}

@keyframes Logo_glitch1__3ZpXp {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, 40deg);
        opacity: 0.1;
    }
    7.2% {
        transform: skew(70deg, -40deg);
        opacity: 1;
    }
    9% {
        transform: skew(0deg, 0deg);
        opacity: 0.2;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-45deg, 0.2deg);
        opacity: 0.75;
    }
    56% {
        transform: skew(0deg, -3deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}
.MainCup_CupDiv__1YVjO {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: MainCup_slideInFromRight___Dw8G 1s ease-out 0s 1;
          animation: MainCup_slideInFromRight___Dw8G 1s ease-out 0s 1;
  position: fixed;
  right: 15px;
  bottom: 3vw;
  z-index: -1;
}

.MainCup_Cup__3OrWn {
    height: 20vw;
    
    flex: auto;
   
    
    
}

.MainCup_Level__3o05r {
  height: 2vw;
  position: relative;
  
  z-index: -2;
  
  -webkit-animation: MainCup_wave__1hhT0 15s infinite;
  
          animation: MainCup_wave__1hhT0 15s infinite;
}






  .MainCup_steam__2jzwM{
  position: relative;
  top:0px;
  left:0px;
  

  -webkit-animation: MainCup_steam__2jzwM 5s linear infinite 3s;
  animation: MainCup_steam__2jzwM 5s linear infinite 3s;
    
  }
  .MainCup_steam__2jzwM:before,
  .MainCup_steam__2jzwM:after{
    
    
    content: " ";
    position: absolute;
    top:20px;
    left:10px;
    border-right: 0px solid transparent;
    border-top: 2px solid #000000;
    border-left: 5px solid #000000;
    border-bottom: 2px solid transparent;
    width: 40px;
    height: 70px;
    z-index: 2;
    border-radius: 80% 0 10% 10%;
   transform: rotate(168deg) skewY(10deg);
  
   -webkit-filter: blur(7px);
   -moz-filter: blur(7px);
   -o-filter: blur(7px);
   -ms-filter: blur(7px);
    filter: blur(7px);
   }
  
  .MainCup_steam__2jzwM:after{
  top:0px;
  left:-50px;
  z-index: 50;
  border-radius: 80% 0 10% 10%;
  transform: rotate(315deg) skewY(10deg);
  
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
  }
  

  @media (max-width: 768px){
    .MainCup_steam__2jzwM:before,
    .MainCup_steam__2jzwM:after{
        width: 20px;
        height: 50px;
    }
  }
  
  
  @-webkit-keyframes MainCup_steam__2jzwM {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @keyframes MainCup_steam__2jzwM {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }



  @-webkit-keyframes MainCup_slideInFromRight___Dw8G {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }



  @keyframes MainCup_slideInFromRight___Dw8G {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }

  

  @-webkit-keyframes MainCup_wave__1hhT0 {
    0% { transform: skew(0deg, 0deg); }
    20% { transform: skew(10deg, 3deg); }
    40% { transform: skew(20deg, 5deg); }
    60% { transform: skew(20deg, 3deg); }
    80% { transform: skew(10deg, 5deg); }
    100% { transform: skew(0deg, 0deg); }
  }

  

  @keyframes MainCup_wave__1hhT0 {
    0% { transform: skew(0deg, 0deg); }
    20% { transform: skew(10deg, 3deg); }
    40% { transform: skew(20deg, 5deg); }
    60% { transform: skew(20deg, 3deg); }
    80% { transform: skew(10deg, 5deg); }
    100% { transform: skew(0deg, 0deg); }
  }
.MainCookies_CookiesDiv__39bje {
 

  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: -1;
  width: 100%;
  height: 300px;
}

.MainCookies_Cookies__1Xrja {
    height: 4vw;
   
}




.MainCookies_Cookie1__2emKf {
  
  -webkit-animation: MainCookies_slideInFromLeft__3ne9A 1s ease-out 0s 1;
  
          animation: MainCookies_slideInFromLeft__3ne9A 1s ease-out 0s 1;
  right: 30vw;
  bottom: 20%;
  position: absolute;
}

.MainCookies_Cookie2__2RpsN {
  
  -webkit-animation: MainCookies_slideInFromLeft__3ne9A 3s ease-out 0s 1;
  
          animation: MainCookies_slideInFromLeft__3ne9A 3s ease-out 0s 1;
  right: 20vw;
  bottom: 1%;
  position: absolute;
}

.MainCookies_Cookie3__1XI-u {
  
  -webkit-animation: MainCookies_slideInFromLeft__3ne9A 2s ease-out 0s 1;
  
          animation: MainCookies_slideInFromLeft__3ne9A 2s ease-out 0s 1;
  right: 5vw;
  bottom: 0;
  position: absolute;
}


  @-webkit-keyframes MainCookies_slideInFromLeft__3ne9A {
    0% {

      transform: translateX(-1000px);
    }

    100% {

      transform: translateX(0);
    }
  }


  @keyframes MainCookies_slideInFromLeft__3ne9A {
    0% {

      transform: translateX(-1000px);
    }

    100% {

      transform: translateX(0);
    }
  }

.SocialMediaButton_SocialMediaButton__2PLOb {
    margin:30px;
    
}
.Footer_Footer__238hM {
    margin: 2rem;
    padding: 0rem;
    

    background-color: #55555577;
    border-style: solid;
    border-radius: 0.3rem;
    font-family: "VT323", monospace;
    
    font-size: 2rem;
    color: rgb(50, 0, 0);
    text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 30px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    -webkit-animation: Footer_textShadow__zf53E 5s linear infinite;
            animation: Footer_textShadow__zf53E 5s linear infinite;
    -webkit-animation: Footer_glitch1__YGnOx 20s infinite;
            animation: Footer_glitch1__YGnOx 20s infinite;
    
}

a {
    color: rgb(50, 0, 0);
    text-decoration: none;
}

a:hover {
    color:#ffe476;
}

h3, h4 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    text-transform: uppercase;
}

h5 {
    margin-top: 0.2em;
    margin-bottom: 0.4em;
    text-transform: uppercase;
}

ul {
    text-align: left;
}

li {
    margin-bottom: 1rem;
}

p{
    text-align: justify;
}

@media (min-width: 1100px) {
    .Footer_Box__3FiRx{
        padding-left: 10rem;
        padding-right: 10rem;
        font-size: 2.5rem;
    }
}


@-webkit-keyframes Footer_textShadow__zf53E {
    0% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00;
    }
    1% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 15px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
    80% {
        text-shadow: 0 0 4px #fff, 0 0 7px #fff, 0 0 10px #ff0000, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
}


@keyframes Footer_textShadow__zf53E {
    0% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00;
    }
    1% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 15px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
    80% {
        text-shadow: 0 0 4px #fff, 0 0 7px #fff, 0 0 10px #ff0000, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
}

@-webkit-keyframes Footer_glitch1__YGnOx {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, -0.3deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-0.1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}

@keyframes Footer_glitch1__YGnOx {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, -0.3deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-0.1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}
.Modal_Modal__1-5dN {
    position: fixed;
    z-index: 500;
   
    width: 80%;
   
    
    
    left: 10%;
    top: 30%;
    
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__1-5dN {
        width: 70%;
        left: 15%;
    }
}
.Backdrop_Backdrop__3j6VK {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgb(100, 15, 0);
    -webkit-filter: blur(5rem);
            filter: blur(5rem);
}
.About_Container__ssyHt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
.Image_Image__1br15{
    height: 15vw;
    margin: 2rem;
    margin-top: 0rem;
    border-style: solid;
    border-radius: 0.3rem;
    box-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 30px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
}


.Work_Container__3mv2c {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

 ul {
padding:0;
margin:0;
  }
.Cv_Content__HSDDu{
    text-align: left;
    margin: 1rem;
}
.Input_Input__3r5Ke {
    width: 90%;
    background-color: #000000;
    border: solid;
    color: #ffcc00;
    outline: none;
    box-sizing: border-box;
    font: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
    
    
    border-radius: 0.3rem;
    
    
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 30px #ffbb00, 
  0 0 3px #ffbb00, 0 0 3px #ff0000, 0 0 10px #ff0000, 0 0 3px #ff0000;
}

.Input_Input__3r5Ke:disabled {
    color: #333;
    cursor: not-allowed;
}



Input:-webkit-autofill,
Input:-webkit-autofill:hover,
Input:-webkit-autofill:focus,
Input:-webkit-autofill:active {
    -webkit-transition: all 9999s ease-in-out 0s;
    transition: all 9999s ease-in-out 0s;
   
    
}

.Input_Success__1hAz7 {
    color: #5C9210;
}

.Input_Danger__2GKMl {
    color: #941775;
}

.Input_Input__3r5Ke:hover {
    color: #ffe476;
    
}






.Input_Label__1qyHr {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;

}





.Input_Invalid__16Mis {
    
    background-color: #550000;

}
