.Button {
    width: 90%;
    
    background-color: #000000;
    border: solid;
    color: #ffcc00;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
    
    
    
    border-radius: 0.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 30px #ffbb00, 
  0 0 3px #ffbb00, 0 0 3px #ff0000, 0 0 10px #ff0000, 0 0 3px #ff0000;
}

.Button:disabled {
    color: #555;
    cursor: not-allowed;
}



.Success {
    color: #5C9210;
}

.Danger {
    color: #941775;
}

.Button:hover {
    color: #ffe476;
    
}



@media (min-width: 1100px) {
    .Button{
        min-width: 190px;
        
    }
}