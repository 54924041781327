.CupDiv {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideInFromRight 1s ease-out 0s 1 ;
  position: fixed;
  right: 15px;
  bottom: 3vw;
  z-index: -1;
}

.Cup {
    height: 20vw;
    
    flex: auto;
   
    
    
}

.Level {
  height: 2vw;
  position: relative;
  
  z-index: -2;
  
  animation: wave 15s infinite;
}






  .steam{
  position: relative;
  top:0px;
  left:0px;
  

  -webkit-animation: steam 5s linear infinite 3s;
  -moz-animation: steam 5s linear infinite 3s;
  -ms-animation: steam 5s linear infinite 3s;
  -o-animation: steam 5s linear infinite 3s;
  animation: steam 5s linear infinite 3s;
    
  }
  .steam:before,
  .steam:after{
    
    
    content: " ";
    position: absolute;
    top:20px;
    left:10px;
    border-right: 0px solid transparent;
    border-top: 2px solid #000000;
    border-left: 5px solid #000000;
    border-bottom: 2px solid transparent;
    width: 40px;
    height: 70px;
    z-index: 2;
    border-radius: 80% 0 10% 10%; 
  
   -webkit-transform: rotate(168deg) skewY(10deg);
   -moz-transform: rotate(168deg) skewY(10deg);
   -ms-transform: rotate(168deg) skewY(10deg);
   -o-transform: rotate(168deg) skewY(10deg);
   transform: rotate(168deg) skewY(10deg);
  
   -webkit-filter: blur(7px);
   -moz-filter: blur(7px);
   -o-filter: blur(7px);
   -ms-filter: blur(7px);
    filter: blur(7px);
   }
  
  .steam:after{
  top:0px;
  left:-50px;
  z-index: 50;
  border-radius: 80% 0 10% 10%; 
  -webkit-transform: rotate(315deg) skewY(10deg);
  -moz-transform: rotate(315deg) skewY(10deg);
  -ms-transform: rotate(315deg) skewY(10deg);
  -o-transform: rotate(315deg) skewY(10deg);
  transform: rotate(315deg) skewY(10deg);
  
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
  }
  

  @media (max-width: 768px){
    .steam:before,
    .steam:after{
        width: 20px;
        height: 50px;
    }
  }
  
  
  @-webkit-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-moz-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-ms-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-o-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }



  @keyframes slideInFromRight {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }

  

  @keyframes wave {
    0% { transform: skew(0deg, 0deg); }
    20% { transform: skew(10deg, 3deg); }
    40% { transform: skew(20deg, 5deg); }
    60% { transform: skew(20deg, 3deg); }
    80% { transform: skew(10deg, 5deg); }
    100% { transform: skew(0deg, 0deg); }
  }