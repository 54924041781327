
@import url("https://fonts.googleapis.com/css?family=VT323&display=swap");
.Box {
    margin: 2rem;
    padding:2rem;
    

    background-color: #55555577;
    border-style: solid;
    border-radius: 0.3rem;
    font-family: "VT323", monospace;
    
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 1100)));
    color: rgb(50, 0, 0);
    text-shadow: 0 0 1px #fff, 0 0 3px #fff, 0 0 10px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00, 0 0 3px #ffbb00;
    animation: glitch1 20s infinite;
    
}

a {
    color: rgb(50, 0, 0);
    text-decoration: none;
}

a:hover {
    color:#ffe476;
}

h3, h4 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    text-transform: uppercase;
}

h5 {
    margin-top: 0.2em;
    margin-bottom: 0.4em;
    text-transform: uppercase;
}

ul {
    text-align: left;
}

li {
    margin-bottom: 1rem;
}

p{
    text-align: justify;
}

@media (min-width: 1100px) {
    .Box{
        padding-left: 10rem;
        padding-right: 10rem;
        font-size: 2.5rem;
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 30px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
        animation: textShadow 5s linear infinite;
    }
}


@keyframes textShadow {
    0% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00;
    }
    1% {
        text-shadow: 0 0 3px #fff, 0 0 7px #fff, 0 0 15px #ffbb00, 0 0 10px #ffbb00, 0 0 20px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
    80% {
        text-shadow: 0 0 4px #fff, 0 0 7px #fff, 0 0 10px #ff0000, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00, 0 0 10px #ffbb00;
    }
}

@keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, -0.3deg);
        opacity: 0.75;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-0.1deg, 0.2deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}