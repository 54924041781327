.Modal {
    position: fixed;
    z-index: 500;
   
    width: 80%;
   
    
    
    left: 10%;
    top: 30%;
    
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 70%;
        left: 15%;
    }
}