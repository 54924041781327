

.LogoDiv{
    margin-left: 50px;
    margin-right: 50px;
    
}

.Logo{
    height: 80px;
    width: 80px;
    filter: drop-shadow(0rem 0rem 0.5rem #ffbb00);
    animation: glitch1 10s infinite;
    
}

.Logo:hover{
    animation: glitch1 0.5s infinite;
}

@keyframes glitch1 {
    0% {
        transform: none;
        opacity: 1;
    }
    7% {
        transform: skew(-0.1deg, 40deg);
        opacity: 0.1;
    }
    7.2% {
        transform: skew(70deg, -40deg);
        opacity: 1;
    }
    9% {
        transform: skew(0deg, 0deg);
        opacity: 0.2;
    }
    10% {
        transform: none;
        opacity: 1;
    }
    27% {
        transform: none;
        opacity: 1;
    }
    30% {
        transform: skew(0.2deg, -0.1deg);
        opacity: 0.75;
    }
    35% {
        transform: none;
        opacity: 1;
    }
    52% {
        transform: none;
        opacity: 1;
    }
    55% {
        transform: skew(-45deg, 0.2deg);
        opacity: 0.75;
    }
    56% {
        transform: skew(0deg, -3deg);
        opacity: 0.75;
    }
    50% {
        transform: none;
        opacity: 1;
    }
    72% {
        transform: none;
        opacity: 1;
    }
    75% {
        
        transform: translate(-1px, -1px);
        opacity: 0.75;
    }
    80% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translate(1px, 1px);
        opacity: 1;
    }
}