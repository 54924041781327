.Nav {


    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;

    font-family: "VT323", monospace;
    font-size: 2rem;
    margin: 0;
    padding: 1em 1em 0em 1em;
    

    
}

.LogoOrder {
    order:3;
}


@media (max-width: 790px){
    .LogoOrder {
        order: 1;
        flex-basis: 100%;
        height: 0;
    }
    .Nav{
        
    }
    .Flex{
        flex-basis: 100%;
    }

}