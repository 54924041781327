.CupDiv {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideInFromRight 1s ease-out 0s 1 ;
}


.Cup {
    height: 150px;
    
    flex: auto;
    z-index: 10;
}

.Level {
  height: 15px;
  position: relative;
  bottom: 100px;
  z-index: 9;
  animation: fillCup 3s ease-out 0s 1;
}


@media (min-width: 768px) {
    .Cup{
        height: 300px;
        
    }
    .Level{
      bottom: 200px;
      height: 32px;
      animation: fillCupBig 3s ease-out 0s 1;
    }
}


  .steam{
  position: relative;
  top:0px;
  left:0px;
  

  /* -webkit-animation: steam 5s linear infinite 3s;
  -moz-animation: steam 5s linear infinite 3s;
  -ms-animation: steam 5s linear infinite 3s;
  -o-animation: steam 5s linear infinite 3s; */
  animation: steam 5s linear infinite 3s;
    
  }
  .steam:before,
  .steam:after{
    
    
    content: " ";
    position: absolute;
    top:20px;
    left:10px;
    border-right: 0px solid transparent;
    border-top: 2px solid #ffffff;
    border-left: 5px solid #ffffff;
    border-bottom: 2px solid transparent;
    width: 40px;
    height: 70px;
    z-index: 2;
    border-radius: 80% 0 10% 10%; 
  
   -webkit-transform: rotate(168deg) skewY(10deg);
   -moz-transform: rotate(168deg) skewY(10deg);
   -ms-transform: rotate(168deg) skewY(10deg);
   -o-transform: rotate(168deg) skewY(10deg);
   transform: rotate(168deg) skewY(10deg);
  
   -webkit-filter: blur(7px);
   -moz-filter: blur(7px);
   -o-filter: blur(7px);
   -ms-filter: blur(7px);
    filter: blur(7px);
   }
  
  .steam:after{
  top:0px;
  left:-50px;
  z-index: 50;
  border-radius: 80% 0 10% 10%; 
  -webkit-transform: rotate(315deg) skewY(10deg);
  -moz-transform: rotate(315deg) skewY(10deg);
  -ms-transform: rotate(315deg) skewY(10deg);
  -o-transform: rotate(315deg) skewY(10deg);
  transform: rotate(315deg) skewY(10deg);
  
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
  }
  

  @media (max-width: 768px){
    .steam:before,
    .steam:after{
        width: 20px;
        height: 50px;
    }
  }
  
  
  @-webkit-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-moz-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-ms-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @-o-keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }
  
  @keyframes steam {
    0% { top: 20px; opacity: .075; visibility: visible; }
    20% { top: 0px; opacity: 0.2; }
    40% { top: -20px; opacity: 0.4; }
    60% { top: -40px; opacity: 0.2; }
    80% { top: -60px; opacity: .075; }
    100% { top: -80px; opacity: 0; visibility: hidden; }
  }



  @keyframes slideInFromRight {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }


  @keyframes fillCup {
    0% { bottom: 50px; opacity: .01; visibility: hidden; }
    20% { bottom: 70px; opacity: 0.2; }
    40% { bottom: 90px; opacity: 0.4; }
    60% { bottom: 110px; opacity: 0.5; }
    80% { bottom: 100px; opacity: .075; }
    100% { bottom: 110px; opacity: 0.80; visibility: visible; }
  }

  @keyframes fillCupBig {
    0% { bottom: 100px; opacity: .01; visibility: hidden; }
    20% { bottom: 120px; opacity: 0.2; }
    40% { bottom: 150px; opacity: 0.4; }
    60% { bottom: 180px; opacity: 0.5; }
    80% { bottom: 150px; opacity: .075; }
    100% { bottom: 200px; opacity: 0.80; visibility: visible; }
  }